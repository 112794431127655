<template>
    <component :is="widgetRenderer" v-bind="widgetProps"></component>
</template>

<script setup lang="ts">
import { importUtils } from 'o365-utils';
import { ref, watch, defineAsyncComponent} from 'vue';
import { API, app } from 'o365-modules';
// import { useAsyncComponent } from 'o365-vue-utils';
import { getDataObjectById } from 'o365-dataobject';

export interface IProps {
    name: string
};

function useAsyncComponent(pImport) {
    return defineAsyncComponent(() => import(pImport));
}

const props = defineProps<IProps>();

const widgetRenderer = ref();
const widgetProps = ref();

watch(() => props.name, () => {
    if (props.name) {
        initWidget();
    } else {
        clearConfig();
    }
}, { immediate: true });

async function initWidget() {
    const config = await getConfig();
    if (config.type == 'highchart') {
        if (config.options == null || config.options.dataObject == null) { clearConfig(); return; }
        widgetRenderer.value = useAsyncComponent('o365.libraries.vue.components.HighChart.vue');
        widgetProps.value = {
            dataObject: getDataObjectById(config.options.dataObject, config.options.appId ?? app.id),
            options: config.options,
            loadDataObject: true
        }
    } else {
        clearConfig();
    }
}

function clearConfig() {
    widgetRenderer.value = undefined;
    widgetProps.value = undefined;
}

async function getConfig() {
    const url = importUtils.getLibUrl(`./${props.name}`);
    return await API.requestGet(url);
}
</script>